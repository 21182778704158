import { CustomButton } from "../components/common/customButton";

import Download from "../assets/icons/download.svg?react";

import "../styles/pages/extansionPage.css";

const text = [
  'Скачайте файл расширения, нажав на кнопку "Скачать расширение".',
  "Распакуйте архив. Откройте Chrome и перейдите по адресу <b>chrome://extensions/.</b> ",
  "Включите «Режим разработчика», переключив тумблер в правом верхнем углу",
  "Нажмите на кнопку «Загрузить распакованное расширение»",
  "Выберите распакованную папку с расширением",
];

export const ExtensionPage = () => {
  return (
    <div className="extension-page default-page">
      <h2 className="mainTitle">Установка расширения Chrome</h2>
      <div className="extension-page-steps">
        {text.map((i, idx) => {
          return (
            <div className="extension-page-step" key={i}>
              <div className="extension-page-step-number">{idx + 1}</div>
              <div dangerouslySetInnerHTML={{ __html: i }} />
              {idx === 0 && (
                <CustomButton leftIcon={Download}>
                  <a href="/files/chrome-ext.zip">Скачать расширение</a>
                </CustomButton>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
